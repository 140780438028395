<template>
  <div v-loading.fullscreen.lock="loading">
    <div class="header-section sinupheader-section">
      <div class="container">
        <div class="row login">
        <div class="col-md-12">
         <div class="row justify-content-center">
           <div class="col-md-8 login-inputs mb-3">
           <login-input></login-input>
          </div>
         </div>
        </div>
        
      </div>
     </div>
    </div>
    <!--Banner Section--->
    <section class="banner-section">
      <div class="container">
        <div class="banner-caption">
          <h2>Restaurant Employee Development Training</h2>
          <p>
            Equip Your Team with Essential Skills and Knowledge Through CRA's Restaurant Employee Development Training and Ensure Compliance with California’s Sexual Harassment Prevention Training Standards
          </p>
        </div>
      </div>
    </section>
    <!--Banner Section--->
    <div class="content-area">
      <div class="container">
        <div class="row">
          <div class="col-md-3"></div>
          <div class="col-md-6">
            <h2 class="text-center mb-5 mt-4">
              Forgot Password
            </h2>
            <p class="mb-4 mt-3">
              Your email address is usually your username for the platform.
              Please enter your email address below and a “Reset Password” link
              will be sent to the email we have on file. If you do not have an
              email address in the system, please email us at:
              <a :href="'mailto:' + infoEmail">{{ infoEmail }}</a
              >.
            </p>
            <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
              <form role="form" @submit.prevent="handleSubmit(onSubmit)">
                <base-input
                  alternative
                  class="mb-3 login-input"
                  name="Email"
                  :rules="{ required: true }"
                  prepend-icon="ni ni-email-83"
                  placeholder="Email"
                  v-model="form.email"
                >
                </base-input>

                <div class="text-center mb-2">
                  <base-button native-type="submit" class="custom-btn"
                    >Submit</base-button
                  >
                </div>
              </form>
              <p class="error" v-if="errors.invalid">{{ errors.invalid }}</p>
            </validation-observer>
          </div>
          <div class="col-md-3"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { Dynamic } from "../../wl";
import LoginInput from "@/views/Pages/LoginInput.vue";
export default {
  components: { LoginInput },
  data() {
    return {
      loading: false,
      form: {
        email: ""
      },
      errors: {
        invalid: ""
      },
      infoEmail: ""
    };
  },
  created: function() {
    this.infoEmail = Dynamic.INFO_EMAIL;
  },
  methods: {
    onSubmit() {
      this.loading = true;
      this.$http
        .post("user/resetLink", {
          user_name: this.form.email
        })
        .then(resp => {
          Swal.fire({
            html: resp.data.message,
            icon: "success",
            confirmButtonClass: "btn btn-success btn-fill"
          });
        })
        .catch(function(error) {
          Swal.fire({
            title: "Error!",
            html: error.response.data.message,
            icon: "error"
          });
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>
<style scoped>
body,
html {
  height: 100%;
}

.error {
  color: red;
  text-align: center;
}
.py-5 {
  padding-bottom: 0px !important;
}
.mt--10 {
  margin-top: -10rem !important;
}
.ctm-btn {
  background-color: #ef8f1d;
  color: #ffffff;
  border-radius: 0 !important;
  font-size: 17px;
  margin-right: 5px;
}
.login-inputs {
  padding-top: 20px;
}
.login{
  margin-right: 0px !important;
}
</style>
